<template>
  <transition v-if="!loading" name="fade">
      <div id="wrapper">
        <nav :class="'navbar bg-transparent p-0 pb-2'">
          <div class="container-fluid">
            <div class="navbar-brand bars me-2 mt-2" @click="this.$router.push({name: 'Home'})">
                <i class="ph-arrow-left"></i>
            </div>

            <img :src="require('../../assets/img/logo-orange.png')" alt="" style="height: 75px;">  

            <span class="navbar-left">
                <div class="navbar-brand">
                <span style="display: -webkit-inline-box;">
                    <h4 style="margin-top: 0.2rem">
                    <i class="ph-bell-ringing text-light me-3 nav-icon circle p-2 bg-default"></i>
                    </h4>
                </span>
                </div>      
            </span>

            <div class="divider mt-4 d-none"></div>
          </div>
        </nav>

        <div class="body mb-4" style="min-height: auto">
          <div class="container" style="padding: 1rem 2rem 0 2rem">
            <div class="row mb-4 mt-2" id="search-row" v-if="form_screen == 5">
              <!-- <h1 class="text-orange text-center fw-bold p-0 mb-4" style="font-size: 1.5rem">Search for any drug and add it here to create a prescription. </h1>               -->

              <h1 class="text-orange text-center fw-bold p-0 mb-4" style="font-size: 1.5rem">Review all your patient's requested drugs</h1> 

              <span class="mt-4" v-if="requested_stocks.length > 0">
                <small>{{ requested_stocks.length }} Drugs selected</small>

                <div class="col-md-6 col-sm-12 col-lg-6" v-for="({name, prescription, image_url, stock_type, description, category, stock_id}, index) in requested_stocks" :key="index">
                  <div class="card mb-3" style="cursor: pointer; box-shadow: 0 4px 6px #eee; border-color: #fafafa" @click="checkDrug({name, description, stock_type, prices, image_url, prescription, category, stock_id})">
                    <div class="card-body p-3">
                      <div class="d-flex">
                        <img :src="axios.defaults.baseURL + '/' + image_url" alt="" class="me-4" style="width: 90px; height: 90px;">
                        <div class="row">
                          <div class="d-flex">  
                            <div class="d-block">
                              <h6 class='text-dark fw-bold mt-2 text-capitalize mb-0'>
                                {{ name }}
                              </h6>
                              <small>{{ stock_type }}</small>
                            </div>
                            <font-awesome-icon class="ms-auto text-orange mt-2" :icon="['fa', 'check']" v-if="this.chosen_stock.includes(name)"></font-awesome-icon>
                          </div>

                          <p class="mt-3">{{ description.substr(0, 50) }}...</p>  
                        </div>
                      </div>
                      <div class="d-flex">
                        <p class="mb-0 text-white fw-bold ps-2 pe-2 rounded-3" style="padding: 2px;" v-if="prescription">
                          <font-awesome-icon class="text-info" style="font-size: 12px" :icon="['fas', 'prescription-bottle']"></font-awesome-icon>
                        </p>

                        <!-- <p class="ms-auto mb-0 text-white bg-dark fw-bold ps-2 pe-2 rounded-3" style="padding: 2px;">Ksh. {{ prices[0].price }}</p> -->
                      </div>                                       
                    </div>
                  </div>                  
                </div>
                
                <div class="row mt-3">
                  <div class="col-sm-12">
                    <button class="btn btn-dark w-100 mt-2" @click="this.form_screen = 2">
                      Next
                    </button>                    
                  </div>
                </div>                
              </span>  

              <span class="mt-4" v-if="requested_stocks.length == 0">
                <no-data :message="'Medicine shows up here.'"></no-data>
              </span>            
            </div>  
            
            <div class="row"> 
              <form id="form" action="#" enctype="multipart/form-data" @submit.prevent="submitPrescription()">

                <div v-if="form_screen == 0" class="col-lg-12 col-md-12">
                  <h1 class="text-orange text-center fw-bold p-0" style="font-size: 1.8rem">{{ prescription.length }} of your products require a presciption.</h1>
                  
                  <figcaption class="mb-4 text-center mt-3 fw-bold">Enter the patient's details</figcaption>
                  
                  <status v-model:status_data=status_data></status>
                  
                  <div class="d-flex mt-3">
                      <div class="d-block mx-auto text-center me-4">
                          <img class="circle p-3" :src="require('../../assets/img/icons8-user-96.png')" alt="" @click="setUserType('same')">
                          <figcaption class="mt-2 ms-2 me-2">This is my own prescription</figcaption>    
                      </div>
                      
                      <div class="d-block mx-auto text-center">
                          <img class="circle p-3" :src="require('../../assets/img/icons8-change-user-96.png')" alt="" @click="setUserType('other')">
                          <figcaption class="mt-2 ms-2 me-2">Someone else's prescription</figcaption>    
                      </div>
                  </div>
                </div>

                <div v-if="form_screen == 1" class="col-lg-12 col-md-12 mt-4">
                  <div class="d-flex">
                    <img class="mx-auto circle p-3" :src="require('../../assets/img/icons8-user-96.png')" alt="">
                  </div>

                  <figcaption class="mb-4 text-center mt-3 fw-bold">Enter the patient's details</figcaption>

                  <status v-model:status_data=status_data></status>

                  <div class="form-row">
                    <label class="fw-bold" for="name">Name</label>

                    <div class="input-group mt-2">
                      <input id="name" type="text" class="form-control" v-model="data.user.name" required>
                    </div>
                  </div>

                  <div class="form-row mt-4">
                    <label class="fw-bold" for="phone_number">Phone Number</label>

                    <div class="input-group mt-2">
                      <input id="name" type="number" class="form-control" v-model="data.user.phone_number" required>
                    </div>
                  </div>

                  <div class="form-row mt-4">
                    <label class="fw-bold" for="hospital">Age Range</label>

                    <div class="input-group mt-2">
                        <select v-model="data.user.age_range" id="gender" class="form-select">
                            <option></option>
                            <option value="0-1">Less than one year</option>
                            <option value="1-12 years">1-12 years</option>
                            <option value="13-19 years">13-19 years</option>
                            <option value="18-35 years">18-35 years</option>
                            <option value="35-50 years">35-50 years</option>
                            <option value="50-65 years">50-65 years</option>
                            <option value="65+ years"></option>
                        </select>
                    </div>
                  </div>

                  <div class="form-row mt-4">
                    <label class="fw-bold" for="hospital">Gender</label>

                    <div class="input-group mt-2">
                        <select v-model="data.user.gender" id="gender" class="form-select">
                            <option></option>
                            <option value="male">Male</option>
                            <option value="female">Female</option>
                        </select>
                    </div>
                  </div> 

                  <div class="d-flex">
                    <button class="btn btn-secondary btn-sm w-25 mt-4 me-2" @click="previousScreen()">
                      <font-awesome-icon class="me-2" :icon="['fas', 'angle-left']"></font-awesome-icon> Back
                    </button>


                    <button class="btn bg-orange text-white btn-sm w-75 mt-4" @click="nextScreen()">
                      Next <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
                    </button> 
                  </div>                           
                </div> 

                <div v-if="form_screen == 2" class="col-lg-12 col-md-12 mt-4">
                  <div class="d-flex">
                    <img class="mx-auto" :src="require('../../assets/img/icons8-hospital-2-64.png')" alt="">
                  </div>
                    
                  <figcaption class="mb-4 text-center mt-3 fw-bold">Enter the hospital and doctor details from the prescription</figcaption>

                  <status v-model:status_data=status_data></status>

                  <div class="form-row">
                      <label class="fw-bold" for="hospital">Hospital/Clinic</label>
                      <div class="input-group mt-2">
                          <input type="text" class="form-control" v-model="data.hospital" required>
                      </div>
                      <small>Put in the name of the hospital who offered the prescription.</small>
                  </div>

                  <div class="form-row mt-4">
                      <label class="fw-bold" for="hospital">Doctor</label>
                      <div class="input-group mt-2">
                          <input type="text" class="form-control" v-model="data.doctor" required>
                      </div>
                      <small>Put in the name of the doctor who offered the prescription.</small>
                  </div> 

                  <div class="form-row mt-4">
                      <label class="fw-bold" for="hospital">Doctor Licence Number</label>
                      <div class="input-group mt-2">
                          <input type="text" class="form-control" v-model="data.doctor_licence_number" required>
                      </div>
                      <small>Enter your Doctor's Licence Number. It should be on your prescription note.</small>
                  </div> 

                  <div class="d-flex">
                      <button class="btn btn-secondary btn-sm w-25 mt-4 me-2" @click="previousScreen(0)">
                          <font-awesome-icon class="me-2" :icon="['fas', 'angle-left']"></font-awesome-icon> Back
                      </button>


                      <button class="btn bg-orange text-white btn-sm w-75 mt-4" @click="nextScreen()">
                          Next <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
                      </button> 
                  </div>                           
                </div> 

                <div v-if="form_screen == 3" class="col-lg-12 col-md-12 mt-4">
                  <div class="d-flex mt-4">
                    <img class="mx-auto" :src="require('../../assets/img/icons8-medicine-2-64.png')" alt="">
                  </div>

                  <span v-for="({name, category, stock_id}, index) in prescription" :key="index">
                    <span v-if="index == drug_screen">
                      <figcaption class="mb-2 text-center mt-4">Your dose and refill instuctions for <strong class="text-orange fw-bold">{{ name }} + {{ stock_id }}</strong> </figcaption>

                      <status v-model:status_data=status_data></status>
                                        
                      <div class="form-row">
                        <label class="fw-bold mt-2" for="hospital">Dose</label>
                          <div class="input-group d-flex mt-2">
                              <div class="input-group d-flex">
                                  <div class="d-block me-2" style="width: 45%;">
                                      <label for="" v-if="category == 'tablet' || category == 'capsule' || !category">No. Tablets</label>
                                      <label for="" v-if="category == 'syrup'">Dose in ml</label>
                                      <label for="" v-if="category == 'suppository' || category == 'pessary' || category == 'ointment'">Apply</label>

                                      <input v-if="category == 'suppository' || category == 'pessary'  || category == 'ointment'" type="text" class="form-control" value="Once" required disabled>

                                      <input v-if="category == 'tablet' || category == 'capsule'" type="number" class="form-control" v-model="dose.no" required>

                                      <select v-if="category == 'syrup'" class="form-control">
                                        <option value="2.5">2.5ml</option>
                                        <option value="5">5ml</option>
                                        <option value="7.5">7.5ml</option>
                                        <option value="10">10ml</option>
                                        <option value="12.5">12.5ml</option>
                                        <option value="15">15ml</option>
                                        <option value="20">20ml</option>
                                      </select>
                                  </div>
                                  
                                  <div class="d-block w-50">
                                      <label for="frequency">Frequency per Day</label>
                                      <select id="frequency" class="form-select" v-model="dose.frequency">
                                          <option value="1">Once a day</option>
                                          <option value="2">Twice a day</option>
                                          <option value="3">Three times a day</option>
                                          <option value="3">Four times a day</option>
                                      </select>
                                  </div>
                              </div>
                          </div>
                          <small v-if="category == 'tablet' || category == 'capsule' || !category">For example, 2 tablets, once a day</small>
                          <small v-if="category == 'syrup'">For example, 20ml, twice a day</small>
                      </div>

                      <div class="form-row mt-3">
                          <label class="fw-bold" for="hospital">Duration</label>
                          <div class="input-group d-flex mt-2">

                              <input type="text" class="form-control me-2" v-model="dose.duration.no" required>

                              <select id="" class="form-select" v-model="dose.duration.type">
                                  <option value="day">Days</option>
                                  <option value="weeks">Weeks</option>
                                  <option value="months">Months</option>
                              </select>
                          </div>
                          <small>How long are you taking this medicine?</small>
                      </div>

                      <div class="form-row mt-3">
                          <label class="fw-bold" for="hospital">Refill</label>
                          <div class="input-group mt-2">
                              <select v-model="refill" id="refill" class="form-select">
                                  <option></option>
                                  <option value="No Refill">No refill </option>
                                  <option value="1 week">1 week</option>
                                  <option value="2 weeks">2 weeks</option>
                                  <option value="1 month">1 month</option>
                                  <option value="3 months">3 months</option>
                              </select>
                          </div>
                          <small>If you have a refill recommendation, put it in here, for example if you need to buy the medicine every week</small>
                      </div>
                      
                      <div class="d-flex" v-if="index+1 != prescription.length">
                          <button class="btn btn-secondary btn-sm w-50 mt-4 me-2" v-if="index > 0" @click="this.drug_screen--">
                              <font-awesome-icon class="me-2" :icon="['fas', 'angle-left']"></font-awesome-icon> Previous Drug
                          </button>

                          <button class="btn btn-secondary btn-sm w-50 mt-4 me-2" v-if="index == 0" @click="previousScreen()">
                              <font-awesome-icon class="me-2" :icon="['fas', 'angle-left']"></font-awesome-icon> Back
                          </button>


                          <button type="button" class="btn bg-orange text-white btn-sm w-50 mt-4" @click="addDrug(index, {name, stock_id})">
                              Next Drug <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
                          </button> 
                      </div>

                      <div class="d-flex" v-if="index+1 == prescription.length">
                          <button class="btn btn-secondary btn-sm w-50 mt-4 me-2" v-if="index == 0" @click="previousScreen()">
                              <font-awesome-icon class="me-2" :icon="['fas', 'angle-left']"></font-awesome-icon> Back
                          </button>

                          <button class="btn btn-secondary btn-sm w-50 mt-4 me-2" v-if="index > 0" @click="this.drug_screen--">
                              <font-awesome-icon class="me-2" :icon="['fas', 'angle-left']"></font-awesome-icon> Previous Drug
                          </button>


                          <button class="btn bg-orange text-white btn-sm w-50 mt-4" type="submit">
                              Submit<font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
                          </button>                         
                      </div>                                 
                    </span>
                  </span>                       
                </div>

                <div v-if="form_screen == 4" class="col-lg-12 col-md-12 mt-4">
                    <div class="d-flex">
                        <img class="mx-auto" :src="require('../../assets/img/icons8-prescription-64.png')" alt="">
                    </div>      

                    <status v-model:status_data=status_data></status>
                           
                    <div class="form-row mt-4">
                        <label class="fw-bold" for="prescription_note">Upload your prescription note</label>
                        <div class="input-group mt-2">
                            <input id="prescription_note" type="file" class="form-control" required @change="imageEvent($event)">
                        </div>
                        <small>Take a photo of your prescription and upload it here.</small>
                    </div>


                    <div class="d-flex">
                        <button class="btn btn-secondary btn-sm w-25 mt-4 me-2" @click="previousScreen()">
                            <font-awesome-icon class="me-2" :icon="['fas', 'angle-left']"></font-awesome-icon> Back
                        </button>

                        <button class="btn bg-orange text-white btn-sm w-75 mt-4" type="submit">
                            Finish <font-awesome-icon class="ms-2" :icon="['fas', 'angle-right']"></font-awesome-icon>
                        </button> 
                    </div>
                </div>  
              </form>
            </div>
          </div>
        </div>      
      </div>
  </transition>
</template>

<style lang="scss" scoped>
  @import '../../assets/scss/layout/_navbar.scss';

  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  
  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
</style>

<script>
  import Status from '../../components/Status'
  import { ToWords } from 'to-words'
  import NoData from '../../components/NoData'

  export default {
    name: 'DoctorPrescription',
    props: {
      create_prescription: {
        required: false, 
        type: Boolean
      },
      patient_id: {
        required: true,
        type: Number
      }
    },
    components: {
      Status,
      NoData
    },   
    data() {
      return {
        prescription_drugs: null,
        form_screen: 5,
        drug_screen: 0,
        prescription: [],
        data: {
          user: {},
          hospital: null,
          doctor: null,
          doctor_licence_number: null,
          drugs: [],
          prescription_note: null
        },
        dose: {
          no: 1,
          frequency: null,
          duration: {
            no: null,
            type: null
          }
        },
        refill: null,
        status_data: {
          "success": false,
          "success_message": null,
          "error": false,
          "error_message": null   
        },
        user_type: null,
        search: null,
        errors: {
          search: null
        },
        requested_stocks: [],
        chosen_stock: []
      }
    },  
    watch: {
      user: function() {
        this.ifNotLoggedIn()
      }
    },
    computed: {
      user () {
        return this.$store.state.user
      },
      loading () {
        return this.$store.state.loading
      },  
    },    
    mounted() {
      this.$store.dispatch("getUser")
    },
    methods: {
      getRequest() {
        let id = localStorage.getItem('doctor_request_id')

        this.axios.get('api/request-prescription/doctor-requests/' + id).then(response => {
          this.requested_stocks = response.data.data.requested_drugs
          this.data.user.name = response.data.data.patient.name
          this.data.user.email = response.data.data.patient.email
          this.data.user.phone_number = response.data.data.patient.phone_number
          this.data.user.id = response.data.data.patient.id
        })        
      },
      imageEvent(event) {
        this.data.prescription_note = event
      },
      routeTo(value) {
        this.$router.push({name: 'Delivery' + value});
      },
      ifNotLoggedIn() {
        if( !this.user.name) {
          this.$router.push({name: 'Login'});
        } else {
          this.data.doctor = this.user.name
          this.data.hospital = this.user.doctor.employer
          this.data.doctor_licence_number =  this.user.doctor.registration_licence_no

          this.getRequest()
        }
      },
      getPrescriptionDrugs() {
        Object.keys(this.cart_stock).forEach(element => {
          if(this.cart_stock[element].prescription) {
            this.prescription_drugs = element
            this.prescription.push(this.cart_stock[element])
          }
        });

        const toWords = new ToWords();
        this.prescription_drugs = toWords.convert(this.cart_stock.length)
      },
      changeScreen() {
        this.$emit('update:screen', this.screen+1)
      },
      nextScreen(value = this.form_screen+1) {
        this.form_screen = value
      },
      previousScreen(value = this.form_screen-1) {
        this.form_screen = value
      },
      submitPrescription() {
        this.status_data.loading = true
        let data = new FormData();

        data.append('user', JSON.stringify(this.data.user))
        data.append('hospital', this.data.hospital)
        data.append('doctor', this.data.doctor)
        data.append('doctor_licence_number', this.data.doctor_licence_number)
        data.append('doctor_id', this.user.id)
        data.append('from_doctor', true)
        data.append('drugs', JSON.stringify(this.data.drugs))

        this.axios.post('api/prescription', data).then((response) => {
          this.status_data.loading = false
          this.status_data.success = true
          this.status_data.success_message = response.data.message
          this.status_data.error = false

          if( !this.create_prescription) {
            setTimeout(() => {
                this.$router.push({name: 'DoctorRequests'})
                this.changeScreen()
            }, 1500);             
          } else {
              this.$router.push({name: 'Profile'})
          }
          
          console.log(response.data);
        }).catch((errors) => {
          this.status_data.loading = false
          this.status_data.success = false
          this.status_data.error_message = 'Prescription not saved.' + errors.message
          console.log(errors);
          this.status_data.error = true
        }) 
      },
      backScreen() {
        this.$emit('update:screen', this.screen-1)    
      },
      setUserType(value) {
        this.user_type = value

        if(this.user_type == 'same') {
          this.form_screen = this.form_screen+2
        } else {
          this.data.user = {
            name: null,
            dob: null,
            age_range: null,
            gender: null,
          }

          this.nextScreen()
        }
      },
      addDrug(index, stock, next = null) {
        if(this.dose.no != null && this.dose.frequency != null && this.dose.duration != null && this.refill != null) {
          if(this.data.drugs[index] == null) {
            this.data.drugs.push({
              stock_id: stock.stock_id,
              dose_no: this.dose.no, 
              dose_frequency: this.dose.frequency, 
              dose_duration: this.dose.duration.no + ' ' + this.dose.duration.type, 
              refill: this.refill
            })
          } else {
            this.data.drugs[index].stock_id = stock.stock_id
            this.data.drugs[index].dose_no = this.dose.no
            this.data.drugs[index].dose_frequency = this.dose.frequency
            this.data.drugs[index].dose_duration = this.dose.duration.no + ' ' + this.dose.duration.type
            this.data.drugs[index].refill = this.refill
          }

          this.dose.no = 1
          this.dose.frequency = null
          this.dose.duration = {
            no: null,
            type: null
          }
          this.refill = null

          if(next == 'next') {
            this.nextScreen()
          }

          if(this.prescription.length-1 != this.drug_screen) {
            this.drug_screen++
          }

          console.log(this.data.drugs);

        } else {
          this.status_data.error = true
          this.status_data.error_message = 'Please put in the dose and refill for ' + stock.name
        }
      },
      doSearch() {
        this.axios.post('api/search-stock').then(response => {
          this.requested_stocks  = response.data.data
          console.log(response.data.data)
        }).catch(errors => { 
          this.status_data.loading = false
          this.status_data.error = true
          this.status_data.success = false
          this.status_data.error_message = 'Errors found.'

          if(errors.data) {
            this.errors = errors.data
          } 

          if(errors.message) {
            this.status_data.error_message = errors.message
          }
        })
      },
      checkDrug(value) {
        if(this.chosen_stock.includes(value.name) === false) {
          this.prescription.push(value)   
          this.chosen_stock.push(value.name)             
        } else {
          let index = this.chosen_stock.indexOf(value.name)
          this.prescription.splice(index, 1); 
          this.chosen_stock.splice(index, 1); 
        }

        console.log(this.prescription)
      }     
    }    
  }
</script>
